

































































































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import BenzAMRRecorder from "benz-amr-recorder"

import sha1 from 'sha1'
import wx from 'weixin-js-sdk'
import { WECHAT_APPID } from '@/utils/constants'
export default Vue.extend({
    data() {
        return {
            scrolltop:768,
            loading: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: [],
            imgcheck: false,
            imgcheckUrl: '',
            imgchatMode: 0,
            liveChatId: '',
            creatorInfo: {},
            targetInfo: {},
            amr: null,
            soundsId: 'null',
        }
    },
    created() {
        this.initWeixin()
    },
    mounted() {
        // console.log(this.$route.query)
        this.liveChatId = this.$route.query.id === 'null' ?  '' : this.$route.query.id
        this.onInit()
    },
    methods: {
        onInit() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },

        getData() {
            this.loading = true
            const { pageNum, pageSize, liveChatId } = this
            const params = { pageNum, pageSize, liveChatId }as any
            request.get('/api/chat/rest_chat/chat/record', params).then((data)=> {
                // console.log(data)
                this.creatorInfo = data.creatorInfo ? data.creatorInfo : ''
                this.targetInfo = data.targetInfo ? data.targetInfo : ''
                const {list, total} = data.chatPage
                list.map((e) => {
                    if (e.chatMode === 4) {
                        const content = JSON.parse((e.content))
                         e.soundsTime = content.time
                        e.soundsStr = content.filePath
                        e.videoPalySrc = '/static/images/hornMute.png'
                        e.isPalyVideo = false
                    }
                    e.location = 'left'
                    if (e.fromId === data.creatorInfo.id || e.toId === data.targetInfo.id) {
                        e.location = 'left'
                    } else {
                        if (e.fromId === data.targetInfo.id || e.toId === data.creatorInfo.id) {
                            e.location = 'right'
                        }
                    }
                })
                if(pageNum==1) this.data = list
                else this.data = list.concat(this.data)
                this.total = total
                // this.$nextTick(() => {
                //     const listTmp: any = this.$refs.list
                //     if(pageNum==1) listTmp.scrollTop = listTmp.scrollHeight
                // })
              this.$nextTick(() => {
                let scrollEl = this.$refs.list;
                if(pageNum==1){
                  this.scrolltop = scrollEl.scrollHeight
                }
                scrollEl.scrollTo({ top:  this.scrolltop });
              });
                setTimeout(()=>this.loading = false, 100)
            })
        },
        getData_old() {
            this.loading = true
            const { pageNum, pageSize } = this
            const { fromId, toId } = this.$route.query
            const params = { pageNum, pageSize, fromId, toId}
            request.get('/api/chat/rest_chat/one_to_one_page', params).then(({list, total}) => {
                // console.log(list)
                if(pageNum==1) this.data = list.reverse()
                else this.data = list.reverse().concat(this.data)
                this.total = total
                // this.loading = false

                this.$nextTick(() => {
                    const listTmp: any = this.$refs.list
                    if(pageNum==1) listTmp.scrollTop = listTmp.scrollHeight
                })
                setTimeout(()=>this.loading = false, 100)
            })
        },
        checkImg(chatMode, content) {
            // this.imgcheck = true
            // this.imgcheckUrl = content
            // this.imgchatMode = chatMode
            const current = 'https://czycwechat.sxktqx.com/chat_files' + content
            wx.previewImage({
                current: current,
                urls: [current],
                success: (res: any) => {
                    // alert(res)
                },
                fail: (err: any) => {
                    alert(JSON.stringify(err))
                },
            })
        },
        clickAudio(item) {
            const {id, soundsStr} = item
            // console.log(this.soundsId)
            // console.log(id)
            // console.log(soundsStr)
            // console.log(soundsTime)
            // console.log(videoPalySrc)
            // console.log(isPalyVideo)
            if (this.soundsId !== id ) {
                this.data.map((e) => {
                    if (e.chatMode === 4) {
                        e.videoPalySrc = '/static/images/hornMute.png'
                        e.isPalyVideo = false
                        if (e.id === id) {
                            e.videoPalySrc = '/static/images/hornPlay.gif'
                            e.isPalyVideo = true
                        }
                    }
                })
                this.soundsId = id
                this.playAmr(soundsStr, id)
            } else {
                this.soundsId = null
                this.data.map((e) => {
                    if (e.chatMode === 4) {
                        e.videoPalySrc = '/static/images/hornMute.png'
                        e.isPalyVideo = false
                    }
                })
                if (!this.amr) {
                    this.playAmr(soundsStr, id)
                } else {
                    this.stopPlayVoice()
                }
            }
        },
        playAmr(soundsStr, id) {
            // console.log(soundsStr)
            // const audioUrl = '/static/images/003.amr'
            // const audioUrl = 'http://czycwechat.sxktqx.com/chat_files' + soundsStr
            const audioUrl = '/chat_files' + soundsStr
            if (this.amr !== null) {
                this.stopPlayVoice()
            }
            // if (isPalyVideo) {
                this.amr = new BenzAMRRecorder(); //建立
                this.amr.initWithUrl(audioUrl) //初始化
                .then(() => {
                    this.amr.play(); //播放
                    this.data.map((e) => {
                        if (e.chatMode === 4) {
                            e.videoPalySrc = '/static/images/hornMute.png'
                            e.isPalyVideo = false
                            if (e.id === id) {
                                e.videoPalySrc = '/static/images/hornPlay.gif'
                                e.isPalyVideo = true
                            }
                        }
                    })
                    this.amr.onEnded(() => {
                        this.data.map((e) => {
                            if (e.chatMode === 4) {
                                e.videoPalySrc = '/static/images/hornMute.png'
                                e.isPalyVideo = false
                            }
                        })
                    });
                }).catch((e) => {
                    // this.videoPalySrc = '/static/images/hornMute.png'
                    this.$message.error("播放录音失败");
                })
            // }
        },
        stopPlayVoice() {
            if (this.amr.isPlaying()) {
                this.amr.stop();
                this.amr = null
            }
        },
        async initWeixin() {
            this.wxConfiged = true
            const timestamp = moment().valueOf()
            const nonceStr = 'cztobaccoh5'
            const jsapiTicket = await request.get('/api/account/office_account/jsapi_ticket')
            console.log('jsapiTicket', jsapiTicket)
            const params = `jsapi_ticket=${jsapiTicket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${window.location.href}`
            console.log('params', params)
            const signature = sha1(params)
            wx.ready(() => {
                this.wxConfiged = true
                wx.onVoiceRecordEnd({ complete: this.stopRecord })
            })
            wx.config({
                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId: WECHAT_APPID, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature,// 必填，签名
                jsApiList: ['startRecord', 'stopRecord', 'onVoiceRecordEnd', 'uploadVoice', 'downloadVoice', 'playVoice', 'pauseVoice', 'stopVoice', 'onVoicePlayEnd', 'chooseImage', 'getLocalImgData', 'previewImage'] // 必填，需要使用的 JS 接口列表
            })
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            return time.format('YYYY-MM-DD HH:mm')
        }
    },
    destroyed() {
        if (this.amr && this.amr.isPlaying()) {
            this.amr.stop();
            this.videoPalySrc = '/static/images/hornMute.png'
            this.amr = null
        }
    },
})
